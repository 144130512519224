<template>
  <div id="order-view-additional-claim" class="mt-2">
    <b-overlay :show="quoteLoading">
      <b-card v-if="quoteSelected.quote" no-body>
        <b-card-body v-if="quoteSelected.quote.claim">
          <b-row>
            <b-col v-if="quoteSelected.quote.claim.closed" cols="12" class="mb-1">
              <div class="text-center">
                <b-badge :variant="`${resolveQuoteStateVariant('claimed')}`" pill>
                  {{ $t('order.claim.title') }}
                </b-badge>
                <span class="font-weight-bold text-center">{{ $t('order.claim_closed') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="quoteSelected.quote.claim.nature" cols="12" class="mb-1">
              <h6 class="font-weight-bold">{{ ` ${$t('order.claim_nature')} : ` }}</h6>
              <p class="card-text p-overflow hide-scroll">
                {{ quoteSelected.quote.claim.nature }}
              </p>
            </b-col>
            <b-col v-if="quoteSelected.quote.claim.comment" cols="12" class="mb-1">
              <h6 class="font-weight-bold">{{ ` ${$t('common.comment')} : ` }}</h6>
              <p class="card-text p-overflow hide-scroll mb-25">
                {{ quoteSelected.quote.claim.comment }}
              </p>
            </b-col>
            <b-col v-if="quoteSelected.quote.claim.refundType" cols="12" class="mb-1">
              <h6 class="font-weight-bold">{{ ` ${$t('common.refund_type')} : ` }}</h6>
              <p class="card-text mb-25">
                {{ quoteSelected.quote.claim.refundType | enumTranslate('quote_claim_refund_type') }}
              </p>
            </b-col>
            <b-col v-if="quoteSelected.quote.claim.refundAmount" cols="12" class="mb-1">
              <h6 class="font-weight-bold">{{ ` ${$t('common.refund_amount')} : ` }}</h6>
              <p class="card-text mb-25">
                {{ quoteSelected.quote.claim.refundAmount | priceFormat }}
              </p>
            </b-col>
          </b-row>
          <b-row v-if="!quoteSelected.quote.claim.closed">
            <b-col v-if="quoteSelected.quote.claim.claimFiles.length" cols="12">
              <h6 class="font-weight-bold">{{ ` ${$tc('common.evidence', 2)} : ` }}</h6>
              <b-container fluid>
                <b-row class="mb-2 img-group hide-scroll" align-h="between">
                  <b-col v-for="(claimFile, index) in quoteSelected.quote.claim.claimFiles" :key="claimFile.filePath" class="mx-n1">
                    <figure class="file-figure m-25">
                      <b-img
                        :src="claimFile.fileUrls[300]"
                        height="100px"
                        :alt="`Claim file ${index}`"
                        rounded
                        @error="imageLoadOnError"
                        @click="openUrl(claimFile.fileUrls.default)"
                      />
                      <figcaption class="font-italic font-weight-light font-small-2 mt-50">{{ claimFile.filePath }}</figcaption>
                    </figure>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import UIHelper from '@/helpers/ui'

export default {
  name: 'OrderViewAdditionalClaim',

  mixins: [UIHelper],

  data() {
    return {}
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'isQuoteOwner', 'quoteLoading']),
  },

  mounted() {},

  methods: {
    imageLoadOnError(e) {
      e.target.src = require('@/assets/images/icons/file-icons/doc2.png')
    },

    openUrl(url) {
      window.open(url, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.img-group {
  max-height: 300px;
  overflow: auto;
}
.file-figure {
  width: inherit;
  text-overflow: ellipsis;
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}
.p-overflow {
  max-height: 200px;
  overflow: auto;

  width: inherit;
  text-overflow: ellipsis;
}
</style>
